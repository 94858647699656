import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import { Link } from 'react-router-dom';
import { activeStatus, fetchAllDataAPI, removeDataFromList } from '../utils/api';
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';
import { FaEdit, FaPlus, FaTrash, FaTrashAlt } from 'react-icons/fa';
import { CiExport } from "react-icons/ci";
import CsvDownloader from 'react-csv-downloader';
import { MDBDataTable } from 'mdbreact';
import Swal from 'sweetalert2';
import { Table } from 'react-bootstrap';

const ProductCategory = () => {
    const [category, setCategory] = useState([]);
    const [isLoading, setIsloading] = useState(false);

    useEffect(() => {
        getAllCategory()
    }, [])

    const getAllCategory = () => {
        setIsloading(true);
        fetchAllDataAPI('warehouse-get-category-data').then((res) => {
            if (res.data.status === 1) {
                setIsloading(false);
                setCategory(...[res.data.category]);
            }
            else {
                setIsloading(false);
                toast.warning("Check your connection")
            }
        })
    }

    const columns = [
        {
            id: 'order_number',
            displayName: 'Order No.'
        },
        {
            id: 'cust_name',
            displayName: 'Name'
        },
        {
            id: 'cust_phone',
            displayName: 'Mobile'
        },
        {
            id: 'subtotal_price',
            displayName: 'Total Price'
        },
        {
            id: 'created_date',
            displayName: 'Order date'
        },
        {
            id: 'created_time',
            displayName: 'Order Time'
        },
        {
            id: 'state',
            displayName: 'State'
        },
        {
            id: 'city',
            displayName: 'City'
        },
        {
            id: 'pincode',
            displayName: 'Pincode'
        },
        {
            id: 'payment_method',
            displayName: 'Payment Method'
        },
        {
            id: 'vendor_name',
            displayName: 'Plateform Name'
        },
    ];
    //const exportOrder = cmsorder;
    const handleCategoryRemove = (catid) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'danger',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        }).then((result) => {
            if (result.isConfirmed) {
                removeDataFromList('warehouse-remove-category-data', catid).then((res) => {
                    if (res.data.status === 1) {
                        Swal.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                        )
                        getAllCategory();
                    }
                })
            }
            else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                Swal.fire(
                    'Cancelled',
                    'Your imaginary file is safe :)',
                    'error'
                )
            }
        })
    }
    const handleCategoryActive = (catid) => {
        activeStatus('warehouse-active-category-status', catid).then((res) => {
            if (res.data.status === 1) {
                getAllCategory();
            }
        })
    }
    const handleCategoryInactive = (catid) => {
        activeStatus('warehouse-inactive-category-status', catid).then((res) => {
            if (res.data.status === 1) {
                getAllCategory();
            }
        })
    }
    return (
        <div className="misDashboard">
            <Helmet>
                <title>SATKARTAR:WAREHOUSE::CATEGORY</title>
            </Helmet>
            <ContentWrapper>
                <div className="page-wrapper breadcum">
                    <div className="page-breadcrumb btnbreadcrum d-none d-sm-flex align-items-center justify-between">
                        <div className="">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/dashboard">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item" aria-current="page">Add Category</li>
                                </ol>
                            </nav>
                        </div>
                        <Link to="/add-category" className="btn btn-md btn-info"><FaPlus /> Add Category</Link>
                    </div>
                    <div className="col-12 col-lg-12 col-xl-12">
                        <div className="card tablecard">
                            <div className="card-body">
                                <Table striped bordered hover size="sm" responsive="sm">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Name</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {category?.map((category, index) => (
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td>{category?.cat_name}</td>
                                                <td>
                                                    {(() => {
                                                        if (category?.cat_status === "Active") {
                                                            return (
                                                                <button className="badge bg-success btnstatus" onClick={() => handleCategoryActive(category?.cat_id)}>Active</button>
                                                            )
                                                        }
                                                        else {
                                                            return (
                                                                <button className="badge bg-danger btnstatus" onClick={() => handleCategoryInactive(category?.cat_id)}>Inactive</button>
                                                            )
                                                        }
                                                    })()}
                                                </td>
                                                <td className="btnsection">
                                                    <Link to={`/edit-category/${category?.cat_id}`} className="btnEdit"><FaEdit /></Link>
                                                    <button className="btnDelete" onClick={() => handleCategoryRemove(category?.cat_id)}><FaTrash /></button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th>#</th>
                                            <th>Name</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </tfoot>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default ProductCategory