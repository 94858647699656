import React, { useState } from 'react'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import LoadingSpinners from '../LoadingSpinners';

const TodayCancleOrder = () => {
    const [isLoading, setIsLoading] = useState(false);

    return (
        <div className="card radius-10 overflow-hidden w-100">
            <div className="card-header border-bottom bg-transparent">
                <div className="d-flex align-items-center">
                    <div>
                        <h6 className="mb-0" style={{color:"#e91e63",fontWeight:"500"}}>Today's Cancle Orders</h6>
                    </div>
                </div>
            </div>

            <div className="progresssection">
                <div className="col-md-12">
                    {isLoading ?
                        <LoadingSpinners />
                        :
                        <div className="progressbody">
                            <div>
                                <CircularProgressbar maxValue={5000} value={450} text={450}
                                    styles={buildStyles({
                                        pathColor: '#e91e63',
                                        textColor: '#e91e63',
                                        trailColor: '#d6d6d6',
                                    })} />
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default TodayCancleOrder