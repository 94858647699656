import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useNavigate } from 'react-router-dom'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { FaProductHunt, FaRegHandPointRight } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { addFormData } from '../utils/api';

const AddCategory = () => {
    const [validated, setValidated] = useState(false);
    const [values, setProductCategory] = useState();
    const navigate = useNavigate();
    const whouseid = localStorage.getItem('whousedata');

    useEffect(() => {
        if (whouseid) {
            navigate('/add-category');
        }
        else {
            navigate("/");
        }
    }, [whouseid]);

    const handleCategorySubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        else {
            try {
                const catdata = { warehouse_id: whouseid, cat_name: values.cat_name };
                addFormData('warehouse-add-category-data', catdata).then((res) => {
                    if (res.data.status === 1) {
                        toast.success("Category has been added successfully!");
                        navigate('/category');
                    }
                    else if (res.data.status === 2) {
                        toast.warning("This Category already exit!!");
                    }
                    else {
                        toast.error("Something Wrongs!!");
                    }
                })
            }
            catch (error) {
                toast.error("Something is worng!!");
            }
        }
        setValidated(true);
    };



    const handleInputs = e => {
        setProductCategory({
            ...values,
            [e.target.name]: e.target.value,
        });
    };
    return (
        <div className="misDashboard">
            <Helmet>
                <title>SATKARTAR:WAREHOUSE::ADD CATEGORY</title>
            </Helmet>
            <ContentWrapper>
                <div className="page-wrapper breadcum">
                    <div className="page-breadcrumb btnbreadcrum d-none d-sm-flex align-items-center justify-between">
                        <div className="">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/dashboard">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item" aria-current="page">ADD Category</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="col-6 col-lg-6 col-xl-6 card cardform">
                        <div className="card border-top border-0 border-4 border-danger">
                            <div className="card-body">
                                <div className="card-title d-flex align-items-center">
                                    <div className="form-heading">
                                        <FaProductHunt className="formIcons" />
                                        <h5 className="mb-0 text-danger">ADD Category</h5>
                                    </div>
                                </div>
                                <hr />
                                <Form noValidate validated={validated} onSubmit={handleCategorySubmit} method="post">
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="12" controlId="validationCustom01">
                                            <Form.Label>Category Name</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Category Name"
                                                name="cat_name"
                                                onChange={handleInputs}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a category name
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                    <Button type="submit">Submit</Button>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default AddCategory