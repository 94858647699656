import React from 'react'
import { Bar, PolarArea } from 'react-chartjs-2';
import { Chart as ChartJS, registerables } from 'chart.js';

ChartJS.register(...registerables)

const data = {
    labels: [
        'Create',
        'Dispatch',
        'Orders',
        'Returns',
    ],
    datasets: [{
        label: 'ORDERS',
        data: [100, 56,87, 93],
        backgroundColor: [
            'rgb(255, 99, 132)',
            'rgb(75, 192, 192)',
            'rgb(255, 205, 86)',
            'rgb(201, 203, 207)',
            'rgb(54, 162, 235)'
        ]
    }]
};

const PolarChart = () => {
    return (
        <div className="polarArea bg-white">
            <PolarArea data={data} />
        </div>
    )
}

export default PolarChart