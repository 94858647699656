import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useNavigate } from 'react-router-dom'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { FaProductHunt, FaRegHandPointRight } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { addFormData, getDataAPIToPincode } from '../utils/api';
import { FcBusinessman } from 'react-icons/fc';

const AddDealer = () => {
    const [validated, setValidated] = useState(false);
    const [values, setDealerValue] = useState();
    const navigate = useNavigate();
    const whouseid = localStorage.getItem('whousedata');
    const [pindata, setPindata] = useState([]);
    const [hiddend, setHiddenValue] = useState({});

    useEffect(() => {
        if (whouseid) {
            navigate('/add-dealer');
        }
        else {
            navigate("/");
        }
    }, [whouseid]);

    const handlePincode = e => {
        if (e.target.value.length === 6) {
            const pincode = e.target.value;
            getDataAPIToPincode('super-admin-get-data-according-to-pincode', pincode).then((res) => {
                if (res?.data.status === 1) {
                    setPindata(...[res?.data.pincode]);
                    setHiddenValue({ ...hiddend, dealer_city: res?.data.pincode.city_name, dealer_state: res?.data.pincode.state_name, dealer_country: res?.data.pincode.country_name });
                }
                else {
                    toast.warning("Check your connection!!");
                }
            })
        }
    }

    const handleDealerSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        else {
            try {
                const dealerdata = { warehouseid: whouseid, dealer_name: values.dealer_name, dealer_email: values.dealer_email, dealer_mobile: values.dealer_mobile, dealer_pincode: values.dealer_pincode, dealer_city: hiddend.dealer_city, dealer_state: hiddend.dealer_state, dealer_country: hiddend.dealer_country };
                addFormData('warehouse-add-dealer-data', dealerdata).then((res) => {
                    if (res.data.status === 1) {
                        toast.success("Dealer has been added successfully!");
                        navigate('/dealer');
                    }
                    else if (res.data.status === 2) {
                        toast.warning("This Dealer already exit!!");
                    }
                    else {
                        toast.error("Something Wrongs!!");
                    }
                })
            }
            catch (error) {
                toast.error("Something is worng!!");
            }
        }
        setValidated(true);
    };


    const handleInputs = e => {
        setDealerValue({
            ...values,
            [e.target.name]: e.target.value,
        });
    };
    return (
        <div className="misDashboard">
            <Helmet>
                <title>SATKARTAR:WAREHOUSE::ADD DEALER</title>
            </Helmet>
            <ContentWrapper>
                <div className="page-wrapper breadcum">
                    <div className="page-breadcrumb btnbreadcrum d-none d-sm-flex align-items-center justify-between">
                        <div className="">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/dashboard">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item" aria-current="page">Add Dealer</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="col-6 col-lg-6 col-xl-6 card cardform">
                        <div className="card border-top border-0 border-4 border-danger">
                            <div className="card-body">
                                <div className="card-title d-flex align-items-center">
                                    <div className="form-heading">
                                        <FcBusinessman className="formIcons" />
                                        <h5 className="mb-0 text-danger">Add Dealer</h5>
                                    </div>
                                </div>
                                <hr />
                                <Form noValidate validated={validated} onSubmit={handleDealerSubmit} method="post">
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="validationCustom01">
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Dealer Name"
                                                name="dealer_name"
                                                onChange={handleInputs}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a dealer name
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="validationCustom02">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Dealer Email"
                                                name="dealer_email"
                                                onChange={handleInputs}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a dealer email
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="validationCustom03">
                                            <Form.Label>Mobile</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Dealer Mobile"
                                                name="dealer_mobile"
                                                onChange={handleInputs}
                                                pattern="[6789][0-9]{9}"
                                                maxLength={10}
                                                minLength={10}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a dealer mobile
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="validationCustom04">
                                            <Form.Label>Pincode</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Pincode"
                                                name="dealer_pincode"
                                                onChange={handleInputs}
                                                pattern="[0-9]{6}"
                                                maxLength={6}
                                                minLength={6}
                                                onKeyUp={handlePincode}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a dealer pincode
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="validationCustom05">
                                            <Form.Label>City</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="City"
                                                name="dealer_city"
                                                value={pindata?.city_name}
                                                onChange={handleInputs}
                                                readOnly={true}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a dealer city
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="validationCustom06">
                                            <Form.Label>State</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="State"
                                                name="dealer_state"
                                                value={pindata?.state_name}
                                                onChange={handleInputs}
                                                readOnly={true}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a dealer state
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="validationCustom07">
                                            <Form.Label>Country</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Country"
                                                name="dealer_country"
                                                onChange={handleInputs}
                                                value={pindata?.country_name}
                                                readOnly={true}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a country
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                    <Button type="submit">Submit</Button>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default AddDealer