import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.scss";
import Home from "./pages/Home";
import Error404 from "./pages/Error404";
import Dashboard from "./pages/Dashboard";
import MobileOTP from "./pages/MobileOTP";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import MyProfile from "./pages/MyProfile";
import 'react-loading-skeleton/dist/skeleton.css'
import AllProduct from "./pages/AllProduct";
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import AllOrder from "./pages/AllOrder";
import AddProduct from "./pages/AddProduct";
import ProductCategory from "./pages/ProductCategory";
import AddCategory from "./pages/AddCategory";
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'mdbreact/dist/css/mdb.css';
import EditCategory from "./pages/EditCategory";
import EditProduct from "./pages/EditProduct";
import Dealer from "./pages/Dealer";
import AddDealer from "./pages/AddDealer";


function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/mobile-otp" element={<MobileOTP />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/my-profile" element={<MyProfile />} />
          <Route path="/all-orders" element={<AllOrder />} />
          <Route path="/category" element={<ProductCategory />} />
          <Route path="/add-category" element={<AddCategory />} />
          <Route path="/all-products" element={<AllProduct />} />
          <Route path="/add-product" element={<AddProduct />} />
          <Route path="/edit-product/:slug" element={<EditProduct />} />
          <Route path="/edit-category/:slug" element={<EditCategory />} />
          <Route path="/dealer" element={<Dealer />} />
          <Route path="/add-dealer" element={<AddDealer />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer position="bottom-center" />
    </>
  );
}

export default App;
